export const msalConfig = {
   auth: {
     clientId: process.env.REACT_APP_MSAL_CLIENT_ID || "",
     authority: process.env.REACT_APP_MSAL_AUTHORITY || "", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
     redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL || "",
     clientSecret: "",
   },
   cache: {
     cacheLocation: "sessionStorage", // This configures where your cache will be stored
     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
   }
 };
 
 // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
 export const loginRequest = {
  scopes: ["openid", "profile", "User.Read", "api://41ceab5b-ae78-4380-9154-6d1b51695f9b/access_as_user"],
  prompt: "select_account",
 };

 // Add the endpoints here for Microsoft Graph API services you'd like to use.
 export const graphConfig = {
     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
 };