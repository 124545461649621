import React from "react";
import { Copyright } from "@material-ui/icons";
import { MyStyle, SECONDARY } from "../../styles/theme";
import { Container } from "react-bootstrap";
import { Divider } from "@material-ui/core";

function Footer() {
   const classes = MyStyle();

   return (
      <div className={classes.footer}>
         <Divider></Divider>
         <Container className="py-2" style={{ fontSize: "10pt", color: SECONDARY, marginTop: "15px" }}>
            Copyright <Copyright fontSize="small" /> {new Date().getFullYear()} InBody CO.,LTD. All right reserved
         </Container>
      </div>
   );
}

export default Footer;
