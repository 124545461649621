import { Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

interface MeetingGridDetailProps {
   data: any;
}

function MeetingGridDetail({ data }: MeetingGridDetailProps) {
   useEffect(() => {}, [data]);
   
   return (
      <div style={{ padding: "5px 50px", maxWidth: "1680px" }}>
         <Paper style={{ padding: "10px 30px", height: "100%", backgroundColor: "aliceblue" }}>
            <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{`내용 (${data.subject})`}</Typography>
            <hr></hr>
            <Typography variant="body1" style={{whiteSpace: "pre-line"}}>{data.description}</Typography>
         </Paper>
      </div>
   );
}

export default MeetingGridDetail;
