import { Chip, createStyles, Divider, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import React, { useEffect } from "react";
import MeetingGrid from "../Meeting/MeetingGrid";
import SaleGrid from "./SaleGrid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontSize: "9pt",
      fontWeight: "bold",
      textAlign: "right"
    },
    headerText2: {
      fontSize: "9pt",
      fontWeight: "bold"
    },
    bodyText: {
      fontSize: "9pt",
    },
    chipText:{
      backgroundColor: "ghostwhite", boxShadow: "grey 0.5px 0.5px 0.5px 0.5px", fontSize: "8pt"
    }
  })
);
interface CompanyGridDetailProps {
   data: any;
}
function CompanyGridDetail({ data }: CompanyGridDetailProps) {
   const classes = useStyles();

   useEffect(() => {}, [data]);

   return (
      <div style={{ padding: "5px 50px" }}>
         <Grid container spacing={1}>
            <Grid item xs={4}>
               <Paper style={{ padding: "10px 20px", backgroundColor: "aliceblue", height: "100%" }}>
                  <Typography variant="subtitle2" align="center">
                     업체정보
                  </Typography>
                  <br></br>
                  <Grid container spacing={1} style={{ backgroundColor: "white", border: "1px solid #e0e0e0" }}>
                     <Grid item xs={12}></Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           업체명
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.companyName}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           사업자등록번호
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.companyRegistrationNo}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           업종구분
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.companyType}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           등급
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.class}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           판매제품
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.mainProduct}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           담당사원
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{data.managerName}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           주소
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText}>{`${data.roadAddress !== null ? data.roadAddress : ""} ${
                           data.detailAddress !== null ? data.detailAddress : ""
                        } ${data.zoneCode !== null ? `(${data.zoneCode})` : ""}`}</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography className={classes.headerText}>
                           비고
                        </Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <Typography className={classes.bodyText} style={{whiteSpace: "pre-line"}}>{data.remark}</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <br></br>
                     </Grid>
                     <Grid item xs={3}></Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.headerText2}>
                           이름
                        </Typography>
                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.headerText2}>
                           전화번호
                        </Typography>
                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.headerText2}>
                           E-mail
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Divider></Divider>
                     </Grid>
                     {/* 담당자 1 */}
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <div style={{ textAlign: "center" }}>
                           <Chip
                              size="small"
                              icon={<FaceIcon />}
                              label={`담당자 1`}
                              className={classes.chipText}
                           />
                        </div>
                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.bodyText}>{data.companyManagerName1}</Typography>
                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.bodyText}>{data.companyManagerTel1}</Typography>
                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography className={classes.bodyText}>{data.companyManagerEmail1}</Typography>
                     </Grid>
                     {/* 담당자 2 */}
                     {data.companyManagerName2 && (
                        <>
                           {" "}
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <div style={{ textAlign: "center" }}>
                                 <Chip
                                    size="small"
                                    icon={<FaceIcon />}
                                    label={`담당자 2`}
                                    className={classes.chipText}
                                 />
                              </div>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerName2}</Typography>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerTel2}</Typography>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerEmail2}</Typography>
                           </Grid>
                        </>
                     )}
                     {/* 담당자 3 */}
                     {data.companyManagerName3 && (
                        <>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <div style={{ textAlign: "center" }}>
                                 <Chip
                                    size="small"
                                    icon={<FaceIcon />}
                                    label={`담당자 3`}
                                    className={classes.chipText}
                                 />
                              </div>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerName3}</Typography>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerTel3}</Typography>
                           </Grid>
                           <Grid item xs={3} style={{ margin: "auto" }}>
                              <Typography className={classes.bodyText}>{data.companyManagerEmail3}</Typography>
                           </Grid>
                        </>
                     )}
                     <Grid xs={12}>
                        <br></br>
                     </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item xs={4}>
               <Paper style={{ padding: "10px 20px", backgroundColor: "aliceblue", height: "100%" }}>
                  <Grid container>
                     <Grid item xs={12}>
                        <Typography variant="subtitle2" align="center">
                           매출정보
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <br></br>
                        <SaleGrid company={data}></SaleGrid>
                     </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item xs={4}>
               <Paper style={{ padding: "10px 20px", backgroundColor: "aliceblue", height: "100%" }}>
                  <Grid container>
                     <Grid item xs={12}>
                        <Typography variant="subtitle2" align="center">
                           미팅이력
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <br></br>
                        <MeetingGrid type="summary" company={data} onRefresh={()=>{}}></MeetingGrid>
                     </Grid>
                  </Grid>
               </Paper>
            </Grid>
         </Grid>
      </div>
   );
}

export default CompanyGridDetail;
