import React from "react";
import { Company } from "../../system/types/interface";
import { TabComponent, TabItemDirective, TabItemsDirective } from "@syncfusion/ej2-react-navigations";
import CompanyGridDialogEdit from "./CompanyGridDialogEdit";
import SaleGrid from "./SaleGrid";

interface CompanyGridDialogProps {
   company: Company;
}

function CompanyGridDialog({ company }: CompanyGridDialogProps) {
   if (company) {
      return (
         <div style={{ width: "1140px", minHeight: "354px" }}>
            <TabComponent heightAdjustMode='Auto'>
               <TabItemsDirective>
                  <TabItemDirective header={{text:"업체정보"}} content={()=>{return <CompanyGridDialogEdit company={company}></CompanyGridDialogEdit>}} />
                  {company.companyId && <TabItemDirective header={{text:"매출정보"}} content={()=>{return <SaleGrid company={company}></SaleGrid>}}></TabItemDirective>}
               </TabItemsDirective>
            </TabComponent>
         </div>
      );
   } else {
      return <div>Loading</div>;
   }
}

export default CompanyGridDialog;
