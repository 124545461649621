import React, { Fragment, ReactElement } from "react";
import "./App.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import LoginRedirect from "./azure/LoginRedirect";
import { LoadingContextProvider } from "./system/context/LoadingContext";
import { UserContextProvider, useUserDispatch } from "./system/context/UserContext";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { UserApi } from "./system/ApiService";
import { loginRequest } from "./azure/authConfig";
import UserRoute from "./route/UserRoute";
import Routes from "./route/Routes";
import { CommonRoutes } from "./system/types/type";

function App() {
   return (
      <>
         <UnauthenticatedTemplate>
            <LoginRedirect></LoginRedirect>
         </UnauthenticatedTemplate>
         <AuthenticatedTemplate>
            <UserContextProvider>
               <LoadingContextProvider>
                  <UserWrapper></UserWrapper>
               </LoadingContextProvider>
            </UserContextProvider>
         </AuthenticatedTemplate>
      </>
   );
}

function UserWrapper(): ReactElement {
   const dispatch = useUserDispatch();
   const { instance, accounts } = useMsal();
   const [isInit, setIsInit] = React.useState(false);
   const history = useHistory();

   React.useEffect(() => {
      // sso-login
      let savedToken = window.sessionStorage.getItem("token");

      const getToken = async () => {
         try {
            let _token;

            // lookinbody.com 메일 있는 경우 inbody.com 계정 찾는 부분
            const account = accounts.find((x) => x.username.includes("@inbody.com"));

            if (_token === undefined) {
               const response = await instance.acquireTokenSilent({
                  ...loginRequest,
                  account: account,
               });
               _token = response.accessToken;
               window.sessionStorage.setItem("token", _token);
            }
            UserApi.SetToken(_token);
            return _token;
         } catch (error) {
            console.error(error);
         }
      };

      const ssoLogin = async (savedToken: string | null) => {
         if (!savedToken) {
            savedToken = (await getToken()) || "";
            if (!savedToken) {
               alert("로그인 정보 처리 중 에러가 발생하였습니다.");
               window.location.replace("https://weareinbody.com");
               return;
            }
         }

         try {
            // 우선 account 에 있는 그룹웨어 이름을 post 로 보내서 로그인
            const account = accounts.find((x) => x.username.includes("@inbody.com"));
            const res = await UserApi.SSOLogin(savedToken, account && account.username);
            // 유저 정보
            dispatch({ type: "LOGIN", item: res.data });
            //메인페이지로 이동
            history.push("/");
         } catch (error: any) {
            if (error.response) {
               if (error.response.status === 401) {
                  window.sessionStorage.removeItem("token");
                  history.push("/");
               } else {
                  alert("등록되지 않은 이메일로 접속이 불가능합니다.");
                  window.location.replace("https://weareinbody.com");
               }
            }
         } finally {
            setIsInit(true);
         }
      };

      ssoLogin(savedToken);
   }, [accounts, dispatch, history, instance]);

   if (isInit) {
      return (
         <Router>
            <Route path={CommonRoutes.login}>
            </Route>
            <UserRoute Component={Routes}></UserRoute>
         </Router>
      );
   } else {
      return <Fragment></Fragment>;
   }
}

export default App;
