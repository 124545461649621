import React from "react";
import { useUserState } from "../../system/context/UserContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import PersistentDrawerLeft from "../../components/PersistentDrawerLeft";
import { MyStyle } from "../../styles/theme";
import { NavLink } from "react-router-dom";

interface HeaderProps {
   open: boolean;
   setOpen: (status: boolean) => void;
 }

function Header({ open, setOpen }: HeaderProps) {
   const classes = MyStyle();
   const user = useUserState();
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const openProfile = Boolean(anchorEl);

   const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleDrawerOpen = () => {
     setOpen(!open);
   };
 
   const handleDrawerClose = () => {
     setOpen(false);
   };

   return (
      <div className={classes.root}>
            <AppBar color="inherit" position="fixed">
               <Toolbar>
                  {/* 메뉴 아이콘 */}
                  <IconButton edge="start" className={classes.menuButton} onClick={handleDrawerOpen} color="inherit" aria-label="menu">
                     <MenuIcon />
                  </IconButton>
                  {/* 로고 - 홈버튼 */}
                  <NavLink to="/" >
                     <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
                  </NavLink>
                  {/* 사이트 이름 */}
                  <Typography variant="h6" className={classes.title}>
                     Dealer Management
                  </Typography>
                  {/* 사용자 프로필 */}
                  <div className={classes.navProfile}>
                     <Typography variant="body2">{user.gwMail}</Typography>
                     <Typography variant="body2">{`${user.teamName} ${user.name}`}</Typography>
                  </div>
                  <div>
                     <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                     >
                        <AccountCircle />
                     </IconButton>
                     <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                           vertical: "top",
                           horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: "top",
                           horizontal: "right",
                        }}
                        open={openProfile}
                        onClose={handleClose}
                     >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                     </Menu>
                  </div>
               </Toolbar>
            </AppBar>
            {/* 사이드 바 */}
            <PersistentDrawerLeft open={open} handleDrawerClose={handleDrawerClose}></PersistentDrawerLeft>
      </div>
   );
}

export default Header;
