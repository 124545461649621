import { Button, Chip, Grid, IconButton } from "@material-ui/core";
import {  AutoCompleteComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { useMemo, useState } from "react";
import { ConstantApi, ErrorHandler } from "../../system/ApiService";
import { Company } from "../../system/types/interface";
import { useDaumPostcodePopup } from "react-daum-postcode";
import FaceIcon from "@material-ui/icons/Face";
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface CompanyGridDialogEditProps {
   company: Company;
}

function CompanyGridDialogEdit({ company }: CompanyGridDialogEditProps) {
   const [data, setData] = useState<Company>(company);
   const [companyType, setCompanyType] = useState<any[]>([]);
   const [classes, setClasses] = useState<any[]>([]);
   const [product, setProduct] = useState<any[]>([]);
   const fields = { text: "value", value: "value" };

   useMemo(() => {
      ConstantApi.GetConstants("company", "companyType")
         .then((res) => {
            setCompanyType(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
      ConstantApi.GetConstants("company", "class")
         .then((res) => {
            setClasses(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
      ConstantApi.GetConstants("company", "mainProduct")
         .then((res) => {
            setProduct(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, []);

   const onChange = (args: any) => {
      setData({ ...data, [(args.target as HTMLInputElement).name]: args.target.value });
   };

   // Daum 주소 검색 팝업
   const open = useDaumPostcodePopup();

   const handleComplete = (addressData: any) => {
      setData({
         ...data,
         sido: addressData.sido,
         sigungu: addressData.sigungu,
         zoneCode: addressData.zonecode,
         roadAddress: addressData.roadAddress,
      });
   };

   const handleClick = () => {
      open({ onComplete: handleComplete });
   };

   // 비고 라인 추가 버튼 이벤트
   const handleClickAdd = () => {
      var remark = "";
      if (data.remark !== null && data.remark !== undefined) remark = data.remark.trim() + "\r\n";
      setData({ ...data, remark: remark + new Date().getFullYear() + "." + (new Date().getMonth() + 1) + "." + new Date().getDate() + " : " });

      if (textObject !== null) textObject.focusIn();
   };

   var textObject: TextBoxComponent | null;

   return (
      <div>
         <br></br>
         <input type="hidden" name="zoneCode" value={data.zoneCode}></input>
         <input type="hidden" name="type" value={data.type}></input>
         <Grid container spacing={1}>
            <Grid item xs={2}>
               <TextBoxComponent placeholder="업체명" name="companyName" value={data.companyName} onChange={onChange} floatLabelType="Always" />
            </Grid>
            <Grid item xs={1}>
            <DropDownListComponent
                  placeholder="업체구분"
                  name="companyKind"
                  dataSource={[{text: "대리점", value: "대리점"}, {text: "딜러", value:"딜러"}]}
                  value={data.companyKind}
                  fields={fields}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent placeholder="사업자등록번호" name="companyRegistrationNo" value={data.companyRegistrationNo} onChange={onChange} floatLabelType="Always" />
            </Grid>
            <Grid item xs={2} style={{ margin: "auto" }}>
               <div style={{ textAlign: "center" }}>
                  <Chip size="small" icon={<FaceIcon />} label={`업체담당자 1`} color="default" />
               </div>
            </Grid>
            <Grid item xs={1}>
               <TextBoxComponent
                  placeholder="이름"
                  name="companyManagerName1"
                  value={data.companyManagerName1}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="전화번호"
                  name="CompanyManagerTel1"
                  value={data.companyManagerTel1}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="E-mail"
                  name="CompanyManagerEmail1"
                  value={data.companyManagerEmail1}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <DropDownListComponent
                  placeholder="업종 구분"
                  name="companyType"
                  dataSource={companyType}
                  value={data.companyType}
                  fields={fields}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <AutoCompleteComponent
                  placeholder="주판매제품"
                  name="mainProduct"
                  dataSource={product}
                  onChange={onChange}
                  value={data.mainProduct}
                  fields={fields}
                  floatLabelType="Always"
               ></AutoCompleteComponent>
            </Grid>
            <Grid item xs={1}>
               <DropDownListComponent
                  placeholder="등급"
                  name="class"
                  dataSource={classes}
                  value={data.class}
                  fields={fields}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2} style={{ margin: "auto" }}>
               <div style={{ textAlign: "center" }}>
                  <Chip size="small" icon={<FaceIcon />} label={`업체담당자 2`} color="default" />
               </div>
            </Grid>
            <Grid item xs={1}>
               <TextBoxComponent
                  placeholder="이름"
                  name="CompanyManagerName2"
                  value={data.companyManagerName2}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="전화번호"
                  name="CompanyManagerTel2"
                  value={data.companyManagerTel2}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="E-mail"
                  name="CompanyManagerEmail2"
                  value={data.companyManagerEmail2}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent placeholder="전화번호" name="tel" value={data.tel} onChange={onChange} floatLabelType="Always" />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent placeholder="팩스" name="pax" value={data.pax} onChange={onChange} floatLabelType="Always" />
            </Grid>
            <Grid item xs={1}>
               <TextBoxComponent placeholder="담당사원" name="ManagerName" value={data.managerName} onChange={onChange} floatLabelType="Always" />
            </Grid>
            <Grid item xs={2} style={{ margin: "auto" }}>
               <div style={{ textAlign: "center" }}>
                  <Chip size="small" icon={<FaceIcon />} label={`업체담당자 3`} color="default" />
               </div>
            </Grid>
            <Grid item xs={1}>
               <TextBoxComponent
                  placeholder="이름"
                  name="CompanyManagerName3"
                  value={data.companyManagerName3}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="전화번호"
                  name="CompanyManagerTel3"
                  value={data.companyManagerTel3}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={2}>
               <TextBoxComponent
                  placeholder="E-mail"
                  name="CompanyManagerEmail3"
                  value={data.companyManagerEmail3}
                  onChange={onChange}
                  floatLabelType="Always"
               />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={6}>
               <Grid container spacing={1}>
                  <Grid item xs={2}>
                     <TextBoxComponent placeholder="국가" name="country" value={data.country} onChange={onChange} floatLabelType="Always"/>
                  </Grid>
                  <Grid item xs={2}>
                     <TextBoxComponent placeholder="시도" name="sido" value={data.sido} onChange={onChange} floatLabelType="Always" />
                  </Grid>
                  <Grid item xs={2}>
                     <TextBoxComponent
                        placeholder="시군구"
                        name="sigungu"
                        value={data.sigungu}
                        onChange={onChange}
                        floatLabelType="Always"
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <TextBoxComponent
                        placeholder="도로명주소"
                        name="roadAddress"
                        value={data.roadAddress}
                        onChange={onChange}
                        floatLabelType="Always"
                     />
                  </Grid>
                  <Grid item xs={8}>
                     <TextBoxComponent
                        placeholder="상세주소"
                        name="detailAddress"
                        value={data.detailAddress}
                        onChange={onChange}
                        floatLabelType="Always"
                     />
                  </Grid>
                  <Grid item xs={4} style={{ margin: "auto", textAlign: "center" }}>
                     <Button color="primary" variant="contained" onClick={handleClick}>
                        주소검색
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={6}>
               <Grid container spacing={1}>
                  <Grid item xs={10}>
                     <TextBoxComponent
                        htmlAttributes={{ rows: "4" }}
                        placeholder="비고"
                        name="remark"
                        value={data.remark}
                        onChange={onChange}
                        multiline={true}
                        floatLabelType="Always"
                        ref={(t) => (textObject = t)}
                     />
                  </Grid>
                  <Grid item xs={2} style={{ margin: "auto", textAlign: "center" }}>
                     <IconButton color="primary" onClick={handleClickAdd}>
                        <AddCircleIcon></AddCircleIcon>
                     </IconButton>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
}

export default CompanyGridDialogEdit;
