import { Day, Inject, Month, PopupOpenEventArgs, ScheduleComponent, ViewDirective, ViewsDirective, Week } from "@syncfusion/ej2-react-schedule";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import React, { useEffect, useState } from "react";
import { CustomDialogEditEventArgs } from "../../system/types/interface";
import { useUserState } from "../../system/context/UserContext";
import { ErrorHandler, MeetingApi } from "../../system/ApiService";
import TitleBox from "../Common/TitleBox";
import { Grid } from "@material-ui/core";

interface MeetingScheduleProps {
   refresh: boolean;
   onRefresh: () => void;
}

function MeetingSchedule({ refresh, onRefresh }: MeetingScheduleProps) {
   // var scheduleObj: ScheduleComponent | null;
   const user = useUserState();
   const [data, setData] = useState<Object[]>([]);
   const [my, setMy] = useState<boolean>(true);

   useEffect(()=>{
      MeetingApi.GetMeetings()
         .then((res) => {
            let temp: Object[] = [];
            res.data.forEach((x: any) => {
               temp.push({
                  Id: x.id,
                  Subject: x.subject,
                  StartTime: new Date(x.startTime),
                  EndTime: new Date(x.endTime),
                  Description: x.description,
                  IsAllDay: x.isAllDay,
                  ManagerId: x.managerId
               });
            });
            if(my)
               setData(temp.filter((x: any)=>x.ManagerId === user.employeeId));
            else
               setData(temp);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [my, user, refresh]);

   const onPopupOpen = (args: PopupOpenEventArgs) => {
      if (args.type === "Editor") {
      }
   };

   const editorTemplate = (props: Object) => {
      return <></>;
   };

   const actionComplete = (args: CustomDialogEditEventArgs): void => {
   };

   const onChange = () =>{
      setMy(!my);
   }

   return (
      <>
         <Grid container>
            <Grid item xs={6}>
               <TitleBox title="미팅 이력 달력" />
            </Grid>
            <Grid item xs={6}>
               <div style={{textAlign: "right"}}>
                  <SwitchComponent checked={my} onChange={onChange}/> 달력에서 내 이력만 보기
               </div>
            </Grid>
         </Grid>
         <ScheduleComponent
            width="100%"
            height="550px"
            // ref={(schedule) => (scheduleObj = schedule)}
            eventSettings={{ dataSource: data, allowAdding: false, allowDeleting: false, allowEditing: false }}
            editorTemplate={editorTemplate}
            actionComplete={actionComplete}
            showQuickInfo={true}
            popupOpen={onPopupOpen}
         >
            <ViewsDirective>
               <ViewDirective option="Day" />
               <ViewDirective option="Week" />
               <ViewDirective option="Month" isSelected={true} />
            </ViewsDirective>
            <Inject services={[Day, Week, Month]} />
         </ScheduleComponent>
      </>
   );
}

export default MeetingSchedule;
