import React from "react";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EventNoteIcon from "@material-ui/icons/EventNote";
import BusinessIcon from "@material-ui/icons/Business";
import { Link, NavLink } from "react-router-dom";
import { SECONDARY } from "../styles/theme";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         display: "flex",
      },
      appBar: {
         transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
      },
      appBarShift: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
         transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      menuButton: {
         marginRight: theme.spacing(2),
      },
      hide: {
         display: "none",
      },
      drawer: {
         width: drawerWidth,
         flexShrink: 0,
      },
      drawerPaper: {
         width: drawerWidth,
         fontSize: "20px",
         textAlign: "center",
         backgroundColor: SECONDARY,
         color: "white",
         "& a:hover": {
            color: "white",
            backgroundColor: SECONDARY,
            filter: "brightness(1.2)",
         },
         "& svg":{
            color:"white",
         }
      },
      drawerHeader: {
         width: drawerWidth,
         display: "flex",
         alignItems: "center",
         padding: theme.spacing(0, 1),
         // necessary for content to be below app bar
         ...theme.mixins.toolbar,
         justifyContent: "flex-end",
      },
      presentation: {
         width: "100vw",
         height: "90vh",
         position: "absolute",
         zIndex: 1,
       },
       presentationShift:{
         pointerEvents: "none"
       }
   })
);

interface PersistentDrawerLeftProps {
   open: boolean;
   handleDrawerClose: () => void;
}

export default function PersistentDrawerLeft({ open, handleDrawerClose }: PersistentDrawerLeftProps) {
   const classes = useStyles();
   const theme = useTheme();

   const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
         return;
      }
      handleDrawerClose();
   };

   return (
      <>
         <CssBaseline />
         <div
            className={open? classes.presentation : classes.presentationShift}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
         >
            <Drawer
               className={classes.drawer}
               variant="persistent"
               anchor="left"
               open={open}
               classes={{
                  paper: classes.drawerPaper,
               }}
            >
               <div className={classes.drawerHeader}>
                  <NavLink to="/">
                     <img src="/images/inbody_logo.png" width="100px" alt="title" style={{ marginRight: "25px" }}></img>
                  </NavLink>
                  <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
               </div>
               <Divider />
               <List>
                  <ListItem button key={"업체 관리"} component={Link} to="/company">
                     <ListItemIcon>
                        <BusinessIcon></BusinessIcon>
                     </ListItemIcon>
                     <ListItemText primary={"업체 관리"} />
                  </ListItem>
                  <ListItem button key={"미팅 관리"} component={Link} to="/meeting">
                     <ListItemIcon>
                        <EventNoteIcon></EventNoteIcon>
                     </ListItemIcon>
                     <ListItemText primary={"미팅 관리"} />
                  </ListItem>
               </List>
               <Divider />
               {/* <List>
                     <ListItem button key={"관리자"}>
                        <ListItemIcon>
                           <SettingsIcon></SettingsIcon>
                        </ListItemIcon>
                        <ListItemText primary={"관리자"} />
                     </ListItem>
                  </List> */}
            </Drawer>
         </div>
      </>
   );
}
