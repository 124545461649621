import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
   ColumnChooser,
   ColumnDirective,
   ColumnsDirective,
   DetailRow,
   DialogEditEventArgs,
   Edit,
   EditSettingsModel,
   ExcelExport,
   Filter,
   FilterSettingsModel,
   GridComponent,
   Inject,
   Page,
   Resize,
   Search,
   Sort,
   Toolbar,
   ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import React, { useCallback, useEffect, useState } from "react";
import { CompanyApi, ErrorHandler } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { Company, CustomDialogEditEventArgs, TotalStatic } from "../../system/types/interface";
import CompanyGridDetail from "./CompanyGridDetail";
import CompanyGridDialog from "./CompanyGridDialog";
import { Grid } from "@material-ui/core";
import TitleBox from "../Common/TitleBox";

function CompanyGrid() {
   const user = useUserState();
   const [data, setData] = useState<TotalStatic[]>([]);
   const [refresh, setRefresh] = useState(false);
   const [type, setType] = useState<string>("DOM");
   const validationRules: object = { required: true };
   const regexRules: object ={ regex: "([0-9]{3})-?([0-9]{2})-?([0-9]{5})" };

   const GetTotalStatics = useCallback(() => {
      CompanyApi.GetTotalStatics(user.employeeId, type)
         .then((res) => {
            setData(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [user, type]);

   useEffect(() => {
      GetTotalStatics();
   }, [GetTotalStatics, refresh]);

   const onChange = (e: any) => {
      setType(e.target.value);
   };

   // GirdObject
   var gridObj: GridComponent | null;
   // Toolbar Click 이벤트
   const toolbarClick = (args: ClickEventArgs | undefined) => {
      if (args !== undefined && args.item !== undefined) {
         if (args.item.id?.includes("excel") && gridObj !== null) {
            gridObj.excelExport();
         }
      }
   };

   const dialogTemplate = (company: Company): any => {
      if(company.managerName === null || company.managerName === undefined){
         company = {...company, managerName: user.name, country: type === "DOM" ? "한국" : ""};
      }
      return <CompanyGridDialog company={company} />;
   };

   // action begin 이벤트
   const actionBegin = (args: DialogEditEventArgs) => {
      if (args.requestType === "save" && args.form) {
         
      } else if (args.requestType === "delete") {
         if (!window.confirm("정말로 삭제하시겠습니까?")) {
            args.cancel = true;
         }
      } 
   };
   // action complete 이벤트
   const actionComplete = (args: CustomDialogEditEventArgs): void => {
      if (args && args.requestType === "save") {
         // action
         if (args.action === "add") {
            CompanyApi.InsertCompany({ ...args.data, type: type })
               .then(() => {
               }).catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               }).finally(()=>{
                  setRefresh(!refresh);
               });
         } else if (args.action === "edit") {
            CompanyApi.UpdateCompany(args.data)
               .then(() => {
               }).catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               }).finally(()=>{
                  setRefresh(!refresh);
               });
         }
      } else if (args && args.requestType === "delete") {
         args.data.forEach((item: Company) => {
            CompanyApi.DeleteCompany(item.companyId)
               .then(() => {
               }).catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               }).finally(()=>{
                  setRefresh(!refresh);
               });
         });
      } else if (args.requestType === "beginEdit" || args.requestType === "add") {
         // dialogTemplate 에서 엔터키 submit event 막기
         args.form?.addEventListener('keydown', function (e) {
            var key = e.key || e.keyCode;
            if (key === 'Enter') {
              e.stopPropagation();
            }
          });
      }
   };

   const editOptions: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: "Dialog", template: dialogTemplate };
   const toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search", "ColumnChooser", "ExcelExport"];
   const filterOptions: FilterSettingsModel = { type: "Excel" };

   const gridTemplate = (props: any) => {
      return <CompanyGridDetail data={props}></CompanyGridDetail>;
   };
   const template: any = gridTemplate;

   const years = [];
   for (let i = new Date().getFullYear()-1; i > 2017; i--) {
      years.push(i);
   }

   return (
      <div>
         <Grid container spacing={2} style={{ padding: "0px 10px 10px 10px" }}>
            <Grid item xs={1}>
               <TitleBox title="업체 관리"></TitleBox>
            </Grid>
            <Grid item xs={1} style={{padding: "15px 0px 0px 0px"}}>
               <RadioButtonComponent label="국내" name="type" value="DOM" checked={true} onChange={onChange} />
            </Grid>
            <Grid item xs={1} style={{padding: "15px 0px 0px 0px"}}>
               <RadioButtonComponent label="해외" name="type" value="INTL" onChange={onChange} />
            </Grid>
         </Grid>
         <GridComponent
            dataSource={data}
            editSettings={editOptions}
            filterSettings={filterOptions}
            allowResizing={true}
            allowFiltering={true}
            allowPaging={true}
            allowSorting={true}
            allowExcelExport={true}
            selectedRowIndex={1}
            selectionSettings={{ type: "Multiple", mode: "Both" }}
            showColumnChooser={true}
            detailTemplate={template}
            toolbar={toolbarOptions}
            toolbarClick={toolbarClick}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
            pageSettings={{ pageSize: 20, pageSizes: true }}
            ref={(g) => {
               gridObj = g;                   
            }}
         >
            <ColumnsDirective>
               <ColumnDirective field="companyId" headerText="companyId" width="0" isPrimaryKey={true} visible={false} showInColumnChooser={false} />
               <ColumnDirective field="type" headerText="type" width="0" visible={false} showInColumnChooser={false} />
               <ColumnDirective field="companyName" headerText="업체명" width="150" validationRules={validationRules}/>
               <ColumnDirective field="companyRegistrationNo" validationRules={regexRules} headerText="사업자등록번호" width="120" />
               <ColumnDirective field="companyKind" headerText="업체구분" width="100" />
               <ColumnDirective field="companyType" headerText="업종구분" width="120" />
               <ColumnDirective field="managerName" headerText="담당사원" width="100"/>
               <ColumnDirective field="country" headerText="국가" width="100"/>
               <ColumnDirective field="mainProduct" headerText="주판매제품" width="120" />
               <ColumnDirective field="class" headerText="등급" width="90" />
               <ColumnDirective field="sum" headerText="총 매출액" textAlign="Right" format="#,###,###,###,###" width="120" />
               {years.map((year) => {
                  return (
                     <ColumnDirective
                        field={`a${year}`}
                        headerText={`'${year-2000}`}
                        textAlign="Right"
                        format="#,###,###,###,###"
                        width="95"
                        key={`year-${year}`}
                        // default로 visible인 것은 최근 4년 매출액만...
                        visible={new Date().getFullYear() - (year) < 3 ? true : false}
                     />
                  );
               })}
               <ColumnDirective field="remark" headerText="비고" width="150"  />
               <ColumnDirective field="sido" headerText="시/도" width="90" visible={false}/>
               <ColumnDirective field="sigungu" headerText="시/군/구" width="90" visible={false}/>
               <ColumnDirective field="detailAddress" headerText="상세주소" width="150" visible={false} />
               <ColumnDirective field="tel" headerText="전화번호" width="120" visible={false}/>
               <ColumnDirective field="pax" headerText="팩스" width="120" visible={false} />
               <ColumnDirective field="companyManagerName1" headerText="담당자1" width="100" visible={false}/>
               <ColumnDirective field="companyManagerTel1" headerText="전화번호1" width="120" visible={false}/>
               <ColumnDirective field="companyManagerEmail1" headerText="이메일1" width="120" visible={false}/>
               <ColumnDirective field="companyManagerName2" headerText="담당자2" width="100" visible={false} />
               <ColumnDirective field="companyManagerTel2" headerText="전화번호2" width="120" visible={false} />
               <ColumnDirective field="companyManagerEmail2" headerText="이메일2" width="120" visible={false} />
               <ColumnDirective field="companyManagerName3" headerText="담당자3" width="100" visible={false} />
               <ColumnDirective field="companyManagerTel3" headerText="전화번호3" width="120" visible={false} />
               <ColumnDirective field="companyManagerEmail3" headerText="이메일3" width="120" visible={false} />
            </ColumnsDirective>
            <Inject services={[Resize, Edit, Toolbar, Filter, Page, Sort, Search, ExcelExport, DetailRow, ColumnChooser]} />
         </GridComponent>
      </div>
   );
}

export default CompanyGrid;
