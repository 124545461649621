import {
   ColumnChooser,
   ColumnDirective,
   ColumnsDirective,
   DetailRow,
   DialogEditEventArgs,
   Edit,
   EditSettingsModel,
   Filter,
   GridComponent,
   Inject,
   Page,
   Resize,
   Search,
   Sort,
   SortSettingsModel,
   Toolbar,
   ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorHandler, SaleApi } from "../../system/ApiService";
import { Company, CustomDialogEditEventArgs, Sale } from "../../system/types/interface";

interface SaleGridProps {
   company: Company;
}

function SaleGrid({ company }: SaleGridProps) {
   const [data, setData] = useState<Sale[]>([]);
   const [refresh, setRefresh] = useState(false);

   const GetSales = useCallback(() => {
      SaleApi.GetSalesByCompanyId(company.companyId)
         .then((res) => {
            setData(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [company]);

   useEffect(() => {
      GetSales();
   }, [GetSales, refresh]);

   const editOptions: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: "Normal" };
   const toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel"];
   const sortingOptions: SortSettingsModel = { columns: [{ field: 'year', direction: 'Descending' }]};

   const actionBegin = (args: DialogEditEventArgs) => {
      if (args.requestType === "save" && args.form) {
      } else if (args.requestType === "delete") {
         if (!window.confirm("정말로 삭제하시겠습니까?")) {
            args.cancel = true;
         }
      }
   };

   const actionComplete = (args: CustomDialogEditEventArgs): void => {
      if (args && args.requestType === "save") {
         if (args.action === "add") {
            SaleApi.InsertSale({...args.data, companyId: company.companyId})
               .then(() => {})
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         } else if (args.action === "edit") {
            SaleApi.UpdateSale(args.data)
               .then(() => {})
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         }
         setRefresh(!refresh);
      } else if (args && args.requestType === "delete") {
         args.data.forEach((item: Sale) => {
            SaleApi.DeleteSale(item.saleId)
               .then(() => {})
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         });
      } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      }
   };

   return (
      <div>
         <GridComponent
            dataSource={data}
            editSettings={editOptions}
            allowPaging={true}
            allowExcelExport={true}
            allowSorting={true}
            sortSettings={sortingOptions}
            selectedRowIndex={1}
            selectionSettings={{ type: "Multiple", mode: "Both" }}
            toolbar={toolbarOptions}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
            pageSettings={{ pageSize: 8 }}
         >
            <ColumnsDirective>
               <ColumnDirective field="saleId" headerText="saleId" width="0" isPrimaryKey={true} visible={false} />
               <ColumnDirective field="companyId" headerText="comapnyId" width="0" visible={false} />
               <ColumnDirective field="year" headerText="연도" width="100" textAlign="Right"/>
               <ColumnDirective field="amount" headerText="매출액" width="150" textAlign="Right" format="#,###,###,###,###"/>
            </ColumnsDirective>
            <Inject services={[Resize, Edit, Toolbar, Filter, Page, Sort, Search, DetailRow, ColumnChooser]} />
         </GridComponent>
      </div>
   );
}

export default SaleGrid;
