import { Typography } from "@material-ui/core";
import React from "react";

interface TitleBoxProps {
   title: string;
}

function TitleBox({ title }: TitleBoxProps) {
   return (
      <div style={{ borderLeft: "8px solid", borderColor: "#64b5f6", paddingLeft: "10px", marginBottom: "2px" }}>
         <Typography variant="h6">{title}</Typography>
      </div>
   );
}

export default TitleBox;
