import { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import CompanyMain from "../pages/Company/CompanyMain";
import Content from "../pages/Layout/Content";
import Footer from "../pages/Layout/Footer";
import Header from "../pages/Layout/Header";
import MeetingMain from "../pages/Meeting/MeetingMain";
// import LandingMain from "../pages/Layout/LandingMain";
import { CommonRoutes } from "../system/types/type";

function Routes(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

   return (
     <div>
      {/* Header - Navigation Menu */}
      <Header open={open} setOpen={setOpen}></Header>
      {/* Body - Content */}
      <Content open={open}>
        <Switch>
          {/* 사이트 메인 페이지 */}
          <Route path={CommonRoutes.root} component={CompanyMain} exact></Route>
          {/* 업체 관리 메인 */}
          <Route path={CommonRoutes.company} component={CompanyMain} exact></Route>
          {/* 미팅 관리 메인 */}
          <Route path={CommonRoutes.meeting} component={MeetingMain} exact></Route>
        </Switch>
      </Content>
       {/* Footer */}
       <Footer></Footer>
     </div>
   );
 }
 
 export default Routes;