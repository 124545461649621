import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import TitleBox from "../../components/Common/TitleBox";
import MeetingGrid from "../../components/Meeting/MeetingGrid";
import MeetingSchedule from "../../components/Meeting/MeetingSchedule";
import CloseIcon from "@material-ui/icons/Close";

function MeetingMain() {
   const [refresh, setRefresh] = useState<boolean>(false);
   const [open, setOpen] = useState<boolean>(true);

   const handleClickClose = () => {
      setOpen(false);
   };

   const onRefresh = () => {
      setRefresh(!refresh);
   };

   useEffect(() => {}, [refresh]);

   return (
      <div>
         {/* 설명 */}
         <Alert key="MeeingGridTitle" variant="primary" style={{ display: open ? "normal" : "none" }}>
            <Grid container>
               <Grid item xs={6}>
                  <Typography variant="h5">미팅 이력 관리</Typography>
               </Grid>
               <Grid item xs={6}>
                  <div style={{ textAlign: "right" }}>
                     <IconButton color="primary" onClick={handleClickClose}>
                        <CloseIcon></CloseIcon>
                     </IconButton>
                  </div>
               </Grid>
            </Grid>
            <br></br>
            <Typography variant="body1">{" - 업체와 미팅 일정 및 내용을 관리할 수 있는 페이지입니다."}</Typography>
            <br></br>
            <Typography variant="body2">{" - 캘린더 우측 상단의 스위치를 통해 내 일정만 표시할 수 있습니다."}</Typography>
            <Typography variant="body2">
               {" - 하단의 '전체 미팅 이력' 테이블의 'Add, Edit, Delete'메뉴를 통해 미팅 이력을 '추가, 수정, 삭제'할 수 있습니다."}
            </Typography>
         </Alert>
         {/* 스케쥴 */}
         <MeetingSchedule refresh={refresh} onRefresh={onRefresh}></MeetingSchedule>
         <br></br>
         {/* 그리드 */}
         <TitleBox title="전체 미팅 이력" />
         <MeetingGrid type="main" onRefresh={onRefresh}></MeetingGrid>
      </div>
   );
}

export default MeetingMain;
