import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import {
   ColumnChooser,
   ColumnDirective,
   ColumnsDirective,
   DetailRow,
   DialogEditEventArgs,
   Edit,
   EditSettingsModel,
   ExcelExport,
   Filter,
   FilterSettingsModel,
   Grid,
   GridComponent,
   Inject,
   Page,
   Resize,
   Search,
   Sort,
   Toolbar,
   ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { ErrorHandler, MeetingApi } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { Company, CustomDialogEditEventArgs, Meeting } from "../../system/types/interface";
import MeetingGridDetail from "./MeetingGridDetail";
import MeetingGridDialog from "./MeetingGridDialog";

interface MeetingGridProps {
   type: string;
   company?: Company;
   onRefresh: () => void;
}

function MeetingGrid({ type, company, onRefresh }: MeetingGridProps) {
   const user = useUserState();
   const [refresh, setRefresh] = useState<boolean>(false);
   const [data, setData] = useState<Meeting[]>([]);
   const main = type === "main" ? true: false;
   const validationRules: object = { required: true };

   useEffect(() => {
      if(type === "main"){
         MeetingApi.GetMeetings()
         .then((res) => {
            let temp: Meeting[] = [];
            res.data.forEach((x: any) => {
               temp.push({
                  ...x,
                  startTime: new Date(x.startTime),
                  endTime: new Date(x.endTime),
               });
            });
            setData(temp);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
      }else if(type === "summary"){
         MeetingApi.GetMeetings(company?.companyId)
         .then((res) => {
            let temp: Meeting[] = [];
            res.data.forEach((x: any) => {
               temp.push({
                  ...x,
                  startTime: new Date(x.startTime),
                  endTime: new Date(x.endTime),
               });
            });
            setData(temp);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
      }
   }, [user, type, company, refresh]);

   // GirdObject
   var gridObj: Grid | null;
   // Toolbar Click 이벤트
   const toolbarClick = (args: ClickEventArgs | undefined) => {
      if (args !== undefined && args.item !== undefined) {
         if (args.item.id?.includes("excel") && gridObj !== null) {
            gridObj.excelExport();
         }
      }
   };

   // action begin 이벤트
   const actionBegin = (args: DialogEditEventArgs) => {
      if (args.requestType === "save" && args.form) {
      } else if (args.requestType === "delete") {
         if (!window.confirm("정말로 삭제하시겠습니까?")) {
            args.cancel = true;
         }
      }
   };
   // action complete 이벤트
   const actionComplete = (args: CustomDialogEditEventArgs): void => {
      if (args && args.requestType === "save") {
         if(args.data.isAllDayString === "true")
            args.data = {...args.data, isAllDay: true};
         else
            args.data = {...args.data, isAllDay: false};
         // action
         if (args.action === "add") {
            MeetingApi.InsertMeeting({...args.data, managerId: user.employeeId, managerName: user.name})
               .then(() => {
                  onRefresh();
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
                  setRefresh(!refresh);
               });
         } else if (args.action === "edit") {
            MeetingApi.UpdateMeeting(args.data)
               .then(() => {
                  onRefresh();
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
                  setData({...data});
                  setRefresh(!refresh);
               });
         }
      } else if (args && args.requestType === "delete") {
         args.data.forEach((item: Meeting) => {
            MeetingApi.DeleteMeeting(item.id)
               .then(() => {
                  onRefresh();
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         });
      } else if (args.requestType === "beginEdit" || args.requestType === "add") { 
         // dialogTemplate 에서 엔터키 submit event 막기
         args.form?.addEventListener('keydown', function (e) {
            var key = e.key || e.keyCode;
            if (key === 'Enter') {
              e.stopPropagation();
            }
          });
      }
   };

   const dialogTemplate = (meeting: Meeting): any => {
      if(company === undefined){
         return <MeetingGridDialog meeting={meeting}></MeetingGridDialog>;
      }
      else{
         return <MeetingGridDialog meeting={{...meeting, subject: company.companyName, companyId: company.companyId}}></MeetingGridDialog>;
      }
   };
   const gridTemplate = (props: any) => {
      return <MeetingGridDetail data={props}></MeetingGridDetail>;
   };
   const template: any = gridTemplate;

   const editOptions: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: "Dialog", template: dialogTemplate };
   const toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search", "ColumnChooser", "ExcelExport"];
   const filterOptions: FilterSettingsModel = { type: "Excel" };

   return (
      <div>
         <GridComponent
            dataSource={data}
            editSettings={editOptions}
            filterSettings={filterOptions}
            allowResizing={true}
            allowFiltering={true}
            allowPaging={true}
            allowSorting={true}
            allowExcelExport={true}
            selectedRowIndex={1}
            selectionSettings={{ type: "Multiple", mode: "Both" }}
            showColumnChooser={true}
            detailTemplate={template}
            toolbar={toolbarOptions}
            toolbarClick={toolbarClick}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
            pageSettings={{ pageSize: main ? 20 : 5, pageSizes: true }}
            ref={(g) => {
               gridObj = g;
            }}
         >
            <ColumnsDirective>
               <ColumnDirective field="id" headerText="id" width="0" isPrimaryKey={true} visible={false} showInColumnChooser={false} />
               <ColumnDirective field="managerId" headerText="작성자 id" width="0" visible={false} />
               <ColumnDirective field="managerName" headerText="작성자" width="70" visible={main}/>
               <ColumnDirective field="subject" headerText="업체명" width="120"visible={main} validationRules={validationRules}/>
               <ColumnDirective field="companyKind" headerText="업체구분" width="70" visible={main}/>
               <ColumnDirective field="companyType" headerText="업종구분" width="100" visible={main}/>
               <ColumnDirective field="companyManagerName" headerText="업체담당자" width="70" visible={main}/>
               <ColumnDirective field="startTime" headerText="시작시간" format={main ? "y-M-d HH:mm a" : "y-M-d"} width={main ? "110" : "90"} validationRules={validationRules} />
               <ColumnDirective field="endTime" headerText="종료시간" format={main ? "y-M-d HH:mm a" : "y-M-d"} width={main ? "110" : "0"} validationRules={validationRules} />
               <ColumnDirective field="description" headerText="내용" width="250" />
               <ColumnDirective field="isAllDay" headerText="하루종일여부" width="0" visible={false} />
            </ColumnsDirective>
            <Inject services={[Resize, Edit, Toolbar, Filter, Page, Sort, Search, ExcelExport, DetailRow, ColumnChooser]} />
         </GridComponent>
      </div>
   );
}

export default MeetingGrid;
