import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import CompanyGrid from "../../components/Company/CompanyGrid";
import CloseIcon from "@material-ui/icons/Close";

function CompanyMain() {
   const [open, setOpen] = useState<boolean>(true);

   const handleClickClose = () => {
      setOpen(false);
   };
   
   return (
      <div>
         {/* 설명 */}
         <Alert key="CompanyGridTitle" variant="primary" style={{ display: open ? "normal" : "none" }}>
            <Grid container>
               <Grid item xs={6}>
                  <Typography variant="h5">업체&딜러 관리</Typography>
               </Grid>
               <Grid item xs={6}>
                  <div style={{textAlign: "right"}}>
                     <IconButton color="primary" onClick={handleClickClose}>
                        <CloseIcon></CloseIcon>
                     </IconButton>
                  </div>
               </Grid>
            </Grid>
            <br></br>
            <Typography variant="body1">{" - 업체와 매출 정보를 관리할 수 있는 페이지입니다."}</Typography>
            <br></br>
            <Typography variant="body2">{" - 업체명 왼쪽 '▶' 버튼을 클릭하여 업체 업체정보와 매출정보를 확인할 수 있습니다."}</Typography>
            <Typography variant="body2">
               {" - 테이블 상단 좌측의 'Add, Edit, Delete, Excel Export'버튼을 클릭하여 정보를 '추가, 수정, 삭제, 엑셀 내려받기'를 할 수 있습니다."}
            </Typography>
            <Typography variant="body2">
               {" - 테이블 상단 우측의 'Columns▼'버튼을 클릭하여 테이블 열 정보를 원하는대로 설정할 수 있습니다."}
            </Typography>
            <Typography variant="body2">
               {" - 기본적으로 최근 4년의 매출액이 표시되지만 'Columns▼'를 통해 더 과거의 매출액도 확인가능합니다."}
            </Typography>
            <Typography variant="body2">
               {
                  " - 엑셀 다운로드는 보이는 데이터 기준으로 다운로드됩니다.'Columns▼'에서 'SelectAll'을 체크한 후 받으면 전체 데이터를 받을 수 있습니다."
               }
            </Typography>
            <Typography variant="body2">{" - 테이블 하단의 item per page 값을 선택해 페이지당 표시 업체 수를 변경할 수 있습니다."}</Typography>
         </Alert>
         {/* 그리드 */}
         <CompanyGrid></CompanyGrid>
      </div>
   );
}

export default CompanyMain;
