import { Grid, Typography } from "@material-ui/core";
import { AutoCompleteComponent, FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useState } from "react";
import { CompanyApi, ErrorHandler } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { Meeting } from "../../system/types/interface";
import { DatePickerComponent, DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Query } from "@syncfusion/ej2-data";

interface MeetingGridDialogProps {
   meeting: Meeting;
}

function MeetingGridDialog({ meeting }: MeetingGridDialogProps) {
   const user = useUserState();
   const [data, setData] = useState<Meeting>({ ...meeting, isAllDayString: "false" });
   const [company, setCompany] = useState<any>([]);

   useEffect(() => {
      CompanyApi.GetCompanies(user.employeeId)
         .then((res) => {
            setCompany(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [user]);

   const fileds = { value: "companyName" };

   var autocompleteObj: AutoCompleteComponent | null;

   const onFiltering = (e: FilteringEventArgs) => {
      e.preventDefaultAction = true;
      var query = new Query().where("companyName", "contains", e.text, true);
      autocompleteObj?.filter(company, query);
   };

   const onChange = (args: any) => {
      if ((args.target as HTMLInputElement).name === "subject") {
         setData({
            ...data,
            [(args.target as HTMLInputElement).name]: args.target.value,
            companyId: args.target.itemData.companyId,
            companyKind: args.target.itemData.companyKind,
            companyType: args.target.itemData.companyType,
         });
      } else if ((args.target as HTMLInputElement).name === "isAllDay") {
         setData({...data, [(args.target as HTMLInputElement).name]: args.target.checked, isAllDayString: args.target.checked ? "true" : "false"})
      } else if ((args.target as HTMLInputElement).name === "startTime") {
         // 하루종일 체크하고 시작시간 변경했을 경우
         if(data.isAllDay)
            setData({...data, [(args.target as HTMLInputElement).name]: args.target.value, endTime: args.target.value});
         else
            setData({...data, [(args.target as HTMLInputElement).name]: args.target.value});
      } else {
         setData({ ...data, [(args.target as HTMLInputElement).name]: args.target.value });
      }
   };

   const itemTemplate = (data: any) => {
      return (
         <div>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
               {`${data.companyName} ${data.country !== null ? `(${data.country})` : ""}`}
            </Typography>
            <Typography variant="body2" style={{ color: "grey" }}>{`${data.companyType !== null ? data.companyType : "-"} / ${
               data.roadAddress !== null ? data.roadAddress : "-"
            }`}</Typography>
         </div>
      );
   };

   return (
      <div style={{ width: "512px", minHeight: "354px" }}>
         <input type="hidden" name="companyId" value={data.companyId}></input>
         <input type="hidden" name="companyKind" value={data.companyKind}></input>
         <input type="hidden" name="companyType" value={data.companyType}></input>
         <input type="hidden" name="managerId" value={data.managerId}></input>
         <input type="hidden" name="managerName" value={data.managerName}></input>
         <input type="hidden" name="isAllDayString" value={data.isAllDayString}></input>
         <Grid container spacing={1}>
            <Grid item xs={12}>
               <AutoCompleteComponent
                  placeholder="업체명"
                  name="subject"
                  ref={(a) => {
                     autocompleteObj = a;
                  }}
                  filtering={onFiltering}
                  dataSource={company}
                  fields={fileds}
                  itemTemplate={itemTemplate}
                  floatLabelType="Always"
                  onChange={onChange}
                  value={data.subject}
               ></AutoCompleteComponent>
            </Grid>
            <Grid item xs={6}>
               <TextBoxComponent
                  placeholder="업체담당자"
                  name="companyManagerName"
                  value={data.companyManagerName}
                  floatLabelType="Always"
               ></TextBoxComponent>
            </Grid>
            <Grid item xs={6}>
               <TextBoxComponent placeholder="장소" name="location" value={data.location} floatLabelType="Always"></TextBoxComponent>
            </Grid>
            {data.isAllDay ? (
               <>
                  <Grid item xs={6}>
                     <DatePickerComponent
                        placeholder="시작시간"
                        name="startTime"
                        value={new Date(data.startTime)}
                        onChange={onChange}
                        floatLabelType="Always"
                        calendarMode="Gregorian"
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <DatePickerComponent placeholder="종료시간" name="endTime"  enabled={false}  value={new Date(data.endTime)} floatLabelType="Always" />
                  </Grid>
               </>
            ) : (
               <>
                  <Grid item xs={6}>
                     <DateTimePickerComponent
                        placeholder="시작시간"
                        name="startTime"
                        value={new Date(data.startTime)}
                        floatLabelType="Always"
                        calendarMode="Gregorian"
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <DateTimePickerComponent placeholder="종료시간" name="endTime"value={new Date(data.endTime)} floatLabelType="Always" />
                  </Grid>
               </>
            )}

            <Grid item xs={12}>
               <CheckBoxComponent label="하루종일" name="isAllDay" checked={data.isAllDay} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
               <TextBoxComponent
                  placeholder="내용"
                  name="description"
                  value={data.description}
                  floatLabelType="Always"
                  multiline={true}
               ></TextBoxComponent>
            </Grid>
         </Grid>
      </div>
   );
}

export default MeetingGridDialog;
