import { AxiosError } from "axios";
import HttpClient from "./http-client";
import { Company, Meeting, Sale } from "./types/interface";

const url = `${window.location.origin}/api`;
const client = new HttpClient(url);

class _BasicApi {
   public client: HttpClient;
   public constructor(client: HttpClient) {
      this.client = client;
   }
}
// Api Class
class _UserApi extends _BasicApi{
   SetToken(token: string) {
      client.setToken(token);
   }
   Logout() {
      client.disableToken();
      return this.client.get("/user/logout");
   }
   SSOLogin(token: string | null, gwMail: string | undefined) {
      if (!token) {
         throw new Error("No token for sso-login");
      }
      this.SetToken(token);
      const form = new FormData();
      form.append("gwMail", gwMail || "");
      return this.client.post("/user/sso", form);
   }
}
class _CompanyApi extends _BasicApi{
   GetCompanies(employeeId: string){
      return this.client.get(`/company?employeeId=${employeeId}`);
   }
   GetTotalStatics(employeeId: string, type: string){
      return this.client.get(`/company/totalstatics?employeeId=${employeeId}&type=${type}`);
   }
   InsertCompany(company: Company){
      return this.client.post(`/company`, company);
   }
   UpdateCompany(company: Company){
      return this.client.put(`/company`, company);
   }
   DeleteCompany(id: number){
      return this.client.delete(`/company/${id}`);
   }
}
class _SaleApi extends _BasicApi{
   GetSales(employeeId: string){
      return this.client.get(`/sale?employeeId=${employeeId}`);
   }
   GetSalesByCompanyId(companyId: number){
      return this.client.get(`/sale/company?companyId=${companyId}`);
   }
   InsertSale(sale: Sale){
      return this.client.post(`/sale`, sale);
   }
   UpdateSale(sale: Sale){
      return this.client.put(`/sale`, sale);
   }
   DeleteSale(id: number){
      return this.client.delete(`/sale/${id}`);
   }
}
class _MeetingApi extends _BasicApi{
   GetMeeting(id: number){
      return this.client.get(`/meeting/${id}`);
   }
   GetMeetings(companyId?: number){
      if(companyId !== undefined){
         return this.client.get(`/meeting?companyId=${companyId}`);
      }else{
         return this.client.get(`/meeting`);
      }
   }
   InsertMeeting(meeting: Meeting){
      return this.client.post(`/meeting`, meeting);
   }
   UpdateMeeting(meeting: Meeting){
      return this.client.put(`/meeting`, meeting);
   }
   DeleteMeeting(id: number){
      return this.client.delete(`/meeting/${id}`);
   }
}
class _ConstantApi extends _BasicApi{
   GetConstant(id: number){
      return this.client.get(`/constant/${id}`);
   }
   GetConstants(tableName: string, columnName: string){
      return this.client.get(`/constant?tableName=${tableName}&columnName=${columnName}`);
   }
}

// Api class Export
export const UserApi = new _UserApi(client);
export const CompanyApi = new _CompanyApi(client);
export const SaleApi = new _SaleApi(client);
export const MeetingApi = new _MeetingApi(client);
export const ConstantApi = new _ConstantApi(client);

// Error Handler
export function ErrorHandler(error: AxiosError): string {
   let msg = "";
   if (error.response) {
      msg += error.response.data;
      if (msg === "" || msg === "[object Object]") {
         //여전히 빈 상태라면...
         switch (error.response.status) {
            case 401:
               msg = "세션이 만료되었습니다.";
               break;
            case 400: //Bad Request
               msg = "데이터 입력이 잘못되었습니다.";
               break;
            case 403: //Forbidden
               msg = "접근할 수 없습니다.";
               break;
            case 404: //NotFound
               msg = "표시할 데이터가 없습니다.";
               break;
            case 405: //method not allowd
               msg = "405: 접근할 수 없습니다.";
               break;
            case 500:
               msg = "서버 에러가 발생하였습니다.";
               break;
            default:
               msg = "알 수 없는 오류입니다. 관리자에게 문의해주세요";
               break;
         }
      }
   } else {
      msg += "서버에서 데이터를 가져올 수 없습니다.";
   }
   return msg;
}
