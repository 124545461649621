import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const MyStyle = makeStyles((theme: Theme) =>
   createStyles({
      // header
      root: {
         flexGrow: 1,
      },
      menuButton: {
         marginRight: theme.spacing(2),
      },
      title: {
         flexGrow: 1,
         marginLeft: "10px",
      },
      navProfile: {
         marginRight: "10px",
      },
      // content
      contentWrapper: {
         marginTop: "80px",
         display: "flex",
         justifyContent: "center",
         flexGrow: 1,
         padding: theme.spacing(2),
         transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: "200ms",
         }),
         marginLeft: 0,
      },
      contentShift: {
         marginTop: "80px",
         display: "flex",
         justifyContent: "center",
         flexGrow: 1,
         padding: theme.spacing(2),
         transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: "200ms",
         }),
         marginLeft: 0,
      },
      contentBody: {
         width: "1860px",
         "@media (max-width: 1680px)": {
            width: "1620px",
         },
         "@media (max-width: 1440px)": {
            width: "1380px",
         },
         "@media (max-width: 1380px)": {
            width: "1320px",
         },
         "@media (max-width: 1279px)": {
            width: "1219px",
         },
      },
      // footer
      footer: {
         height: "80px",
         width: "100%",
         //position: "absolute",
         bottom: 0,
         padding: "10px",
         marginTop: theme.spacing(3),
         textAlign: "center",
      },
   })
);
