import { AuthorityUser } from "./interface";

export const InitUser: AuthorityUser = {
   employeeId: "",
   employeeNo: "",
   name: "",
   gwName: "",
   gwMail: "",
   jobTitle: "",
   jobPosition: "",
   workplace: "",
   teamId: 0,
   teamName: "",
   parentTeamId: 0,
   parentTeamName: "",
   role: "",
   positionCode: "",
   authority: [],
 };